<template>
  <v-app>
    <v-content>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-h5">File Upload</v-card-title>
              <v-card-text>
                <input type="file" ref="fileInput" @change="addFile" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="mt-2" cols="12">
            <v-card>
              <v-card-title class="text-h5">Vorhandene Dateien</v-card-title>
              <v-data-table
                :headers="header"
                :items="files"
                :items-per-page="25"
                class="elevation-1"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.type }}</td>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.upload_date }}</td>
                    <td>
                      <v-btn icon @click.stop="downloadFile(props.item.hash)"
                        ><v-icon>mdi-cloud-download</v-icon></v-btn
                      >
                      <v-btn icon @click.stop="removeFile(props.item.id)"
                        ><v-icon color="error">mdi-delete</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'Demo',
  components: {},
  props: {},
  data() {
    return {
      header: [
        { text: 'ID', value: 'id' },
        { text: 'Typ', value: 'type' },
        { text: 'Dateiname', value: 'name' },
        { text: 'Upload Datum', value: 'created' },
        { text: 'Aktionen', value: 'actions' },
      ],
      files: [],
    }
  },
  computed: {},
  mounted() {
    this.loadFiles()
  },
  methods: {
    loadFiles() {
      axios.get('/media')
        .then(response => {
          this.files = response.data
        })
    },
    removeFile(id) {
      axios.delete(`/media/${id}`)
        .then(response => {
          this.files = this.files.filter(obj => obj.id !== id)
        })
    },
    downloadFile(id) {
      const url = `/media/${id}`
      window.location = url
    },
    addFile() {
      const el = this.$refs.fileInput
      const file = el.files[0]
      const form = new FormData()
      form.append('file', file)
      form.append('meta', JSON.stringify({
        type: 'file',
      }))
      axios.post('/media', form)
        .then(response => {
          const data = response.data
          this.files.push(data)
        })
    },
  },
}
</script>
